export default {
  el: {
    table: {
      emptyText: '暂无数据',
      confirmFilter:'筛选',
      resetFilter:'重置',
      clearFilter:'全部',
      sumText:'合计'
    },
    pagination:{
      goto:'前往',
      pagesize:'条/页',
      total:`总共{total}条`,
      pageClassifier:'页'
    }
  }
};
