import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/index' },
  { path: '/index', component: resolve => require(['@/views/index'], resolve), meta: { title: '首页' } },
  { path: '/about', component: resolve => require(['@/views/aboutUs/index'], resolve), meta: { title: '关于我们' } },
  { path: '/cpyfw', component: resolve => require(['@/views/productAndService/index'], resolve), meta: { title: '产品与服务' } },
  { path: '/fual', component: resolve => require(['@/views/servicecase/index'], resolve), meta: { title: '服务案例' } },
]

const router = new VueRouter({
  // mode: 'history',
  routes
})

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location)
}

export default router
